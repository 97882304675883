import { Helmet } from "react-helmet";
import { Heading, Button, Img } from "../components";
import { useNavigate } from "react-router-dom";
import React from "react";

export default function Home() {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Top-Level Domain Information | TLD Ownership Details</title>
        <meta
          name="description"
          content="Get detailed information on top-level domain ownership, provider, minted domains, and registration date. Verify TLD details now."
        />
      </Helmet>
      {/* main content section */}
      <div className="flex w-full h-screen items-center justify-center bg-gray-200">
        <div className="flex flex-col items-center gap-8">
          {/* header section */}
          <div className="flex flex-col items-center">
            <Img
              src="images/img_header_logo.svg"
              alt="logo image"
              className="h-[80px] w-[366px] object-contain"
            />
          </div>
          {/* content section */}
          <div className="flex flex-col items-center gap-8">
            <Heading as="h1" className="tracking-[6.5px] text-center">
              Page Not Found
            </Heading>
            <Button
              size="xl"
              variant="fill"
              shape="circle"
              className="w-[154px] h-[40px] !rounded-[10px] mt-3"
              onClick={() => navigate("/")}
            >
              Go Back
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
