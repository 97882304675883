import { Helmet } from "react-helmet";
import { Heading, Button, Input, Img } from "../components";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { fetchTldInfo } from "api/tldApi";
import { Spinner } from "components/Spinner";
import { BACKEND_URL } from "config/config";

const Detail = ({ label, value, hasBorder }) => {
  return (
    <div
      className={`flex justify-between ${
        hasBorder ? "border-b border-gray-200" : ""
      } pb-2`}
    >
      <Heading size="headingxs" as="h3">
        {label}
      </Heading>
      <Heading size="texts" as="h4" className="text-right">
        {value}
      </Heading>
    </div>
  );
};

export default function Home() {
  const [domainInput, setDomainInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [tldInfo, setTldInfo] = useState(undefined);
  const handleSearch = async () => {
    setTldInfo(undefined);
    if (!domainInput.trim()) {
      toast.error("Please enter a Top-Level Domain to search");
      return;
    }
    setLoading(true);
    try {
      const data = await fetchTldInfo(domainInput);
      console.log(data);
      setTldInfo(data);
    } catch (error) {
      console.error(error);
      toast.error(
        error?.response?.data?.error ||
          "An error occurred while searching for the domain"
      );
    }
    setLoading(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  function formatDate(dateString) {
    if (!dateString) return "Unknown";
    try {
      const date = new Date(dateString);

      const pad = (num) => num.toString().padStart(2, "0");

      const day = pad(date.getDate());
      const month = pad(date.getMonth() + 1);
      const year = date.getFullYear();

      const hours = pad(date.getHours());
      const minutes = pad(date.getMinutes());

      return `${day}-${month}-${year}, ${hours}:${minutes}`;
    } catch (err) {
      return "";
    }
  }

  return (
    <>
      <Helmet>
        <title>Top-Level Domain Information | TLD Ownership Details</title>
        <meta
          name="description"
          content="Get detailed information on top-level domain ownership, provider, minted domains, and registration date. Verify TLD details now."
        />
      </Helmet>
      {/* main content section */}
      <div className="flex w-full flex-col items-center gap-[90px] bg-gray-200 py-[90px] lg:gap-[90px] lg:py-8 md:gap-[67px] md:py-5 sm:gap-[45px] sm:py-4">
        {/* header section */}
        <div className="container-xs flex flex-col px-14 lg:p-5 md:p-5 md:px-5 sm:px-4 items-center">
          <Img
            src="images/img_header_logo.svg"
            alt="logo image"
            className="h-[80px] w-[366px] object-contain"
          />
        </div>
        {/* search section */}
        <div className="mb-1 flex flex-col gap-8 self-stretch">
          <div className="flex justify-center">
            <div className="container-xs flex justify-center lg:p-5 md:p-5">
              <div className="w-full">
                <div className="flex flex-col items-start justify-center gap-6 rounded bg-white px-8 py-[30px] shadow-xs sm:p-4">
                  <div className="flex flex-col items-start gap-[26px] self-stretch">
                    <Heading as="h1">Search Top-Level Domain</Heading>
                    <div className="h-px w-full self-stretch bg-gray-500" />
                  </div>
                  <div className="flex items-center gap-[18px] self-stretch md:flex-col">
                    <Input
                      shape="round"
                      name="Wallet Input"
                      placeholder={`Type Top-Level Domain in here`}
                      className="sm:px-4"
                      value={domainInput}
                      onChange={(e) => setDomainInput(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                    <Button
                      color={loading ? "white" : undefined}
                      shape="round"
                      className="lg:text-[15px] sm:px-4"
                      onClick={handleSearch}
                    >
                      {loading ? <Spinner /> : "Search"}
                    </Button>
                  </div>
                  <Heading size="textxs" as="h2">
                    <span className="font-bold text-blue_gray-900_01">
                      Sources:
                    </span>
                    <span className="text-blue_gray-900_01">
                      &nbsp;Ethereum Naming Service, Unstoppable Domains,
                      Handshake, Freename, Decentraweb, EDNS. Domains, AllDomains and D3
                    </span>
                  </Heading>
                </div>
              </div>
            </div>
          </div>
          {/* results section */}
          <div className="flex justify-center">
            <div className="container-xs flex justify-center lg:p-5 md:p-5">
              {tldInfo ? (
                <div className="flex w-full flex-col items-start gap-[26px] rounded bg-white p-8 shadow-xs sm:p-4">
                  <Heading as="h2">Search result</Heading>
                  <div className="h-px self-stretch bg-gray-500" />
                  {tldInfo?.status !== "Registered" ? (
                    <div className="self-stretch">
                      <div className="flex flex-col gap-[30px]">
                        <Detail label="TLD status" value="Available" />
                      </div>
                    </div>
                  ) : (
                    <div className="relative self-stretch lg:h-auto md:h-auto px-8">
                      <div className="flex flex-col gap-4">
                        <Detail
                          label="TLD status"
                          value="Registered"
                          hasBorder
                        />
                        <Detail
                          label="Owner address"
                          value={tldInfo.ownerAddress}
                          hasBorder
                        />
                        <Detail
                          label="Domain provider"
                          value={tldInfo.provider.replace("Unstoppable","Unstoppable Domains")}
                          hasBorder
                        />
                        <Detail
                          label="Domains minted"
                          value={
                            tldInfo.mintedDomains === ""
                              ? "Unknown"
                              : tldInfo.mintedDomains>0?"Yes":"No"
                          }
                          hasBorder
                        />
                        <Detail
                          label="Registration date"
                          // value="01-01-2022, 14:28"
                          value={formatDate(tldInfo.registrationDate)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center gap-8 self-stretch rounded border-2 border-dashed border-blue_gray-900_01 px-14 py-[58px] md:p-5 sm:p-4 w-full">
                  <Img
                    src="images/img_network_search.svg"
                    alt="network image"
                    className="h-[128px] w-[128px]"
                  />
                  <Heading size="headingxs" as="h3">
                    You have not searched for a TLD yet
                  </Heading>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
