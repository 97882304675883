import React from "react";
const sizes = {
  textxs: "text-sm font-medium",
  texts: "text-lg font-medium lg:text-[15px]",
  headingxs: "text-lg font-black lg:text-[15px]",
  headings: "text-2xl font-black lg:text-xl md:text-[22px]",
};
const Heading = ({
  children,
  className = "",
  size = "headings",
  as,
  ...restProps
}) => {
  const Component = as || "h6";
  return (
    <Component
      className={`text-blue_gray-900_01 font-circularstd ${className} ${sizes[size]}`}
      {...restProps}
    >
      
      {children}
    </Component>
  );
};
export { Heading };
