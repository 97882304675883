import React from "react";
import PropTypes from "prop-types";
const shapes = { round: "rounded" };
const variants = {
  fill: {
    gray_200: "bg-gray-200 text-gray-500",
    deep_orange_400: "bg-deep_orange-400 text-white",
  },
};
const sizes = { xs: "h-[56px] px-[34px] text-lg" };
const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant = "fill",
  size = "xs",
  color = "deep_orange_400",
  ...restProps
}) => {
  return (
    <button
      className={`${className} flex flex-row items-center justify-center text-center cursor-pointer text-lg font-black min-w-[214px] rounded ${
        (shape && shapes[shape]) || ""
      } ${(size && sizes[size]) || ""} ${
        (variant && variants[variant]?.[color]) || ""
      }`}
      {...restProps}
    >
      
      {!!leftIcon && leftIcon} {children} {!!rightIcon && rightIcon}
    </button>
  );
};
Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  shape: PropTypes.oneOf(["round"]),
  size: PropTypes.oneOf(["xs"]),
  variant: PropTypes.oneOf(["fill"]),
  color: PropTypes.oneOf(["gray_200", "deep_orange_400"]),
};
export { Button };
