import axios from "axios";
import { BACKEND_URL } from "config/config";

export const fetchTldInfo = async (domain) => {
  try {
    const response = await axios.get(`${BACKEND_URL}/api/v1/tld-info`, {
      params: { domain }
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};
